import React from 'react';

import { useRouter } from 'next/router';

import { FormattedMessage } from 'react-intl';

import { Box, Button, Stack, alpha } from '@mui/material';

import { ROUTES } from '@arrived/common';
import { Invest } from '@arrived/features-invest';
import { Offering } from '@arrived/models';

import { NextMuiLink } from '../Link';

interface PropertyCardsProps {
  offerings: Offering[];
}
export const PropertyCards = (props: PropertyCardsProps) => {
  const { offerings } = props;

  const router = useRouter();

  const browseAllButton = (
    <Button
      component={NextMuiLink}
      href={ROUTES.properties.base}
      variant="contained"
      color="primary"
      sx={{ width: '100%', maxWidth: 'none' }}
    >
      <FormattedMessage id="homepage-v2.property-cards.browse-all.button" />
    </Button>
  );

  return (
    <Stack
      sx={{
        mt: { xs: 4, md: 10 },
        px: { xs: 2 },
        gap: 2,
      }}
      data-e2e="homepage-v2.property-cards"
      width="100%"
    >
      <Stack sx={{ overflowX: 'auto' }} flexDirection="row" justifyContent="space-around">
        <Stack direction="row" gap={3} width="1400px">
          {offerings.map((offering, idx) => (
            <Stack key={offering.id} height="332px" position="relative">
              <Invest.Card
                role="link"
                height="100%"
                aspectRatio="1/1"
                flex={1}
                offering={offering}
                onPress={() =>
                  router.push({
                    pathname: ROUTES.public.properties.propertyDetails.base,
                    query: { shortName: offering.shortName },
                  })
                }
              />
              {idx === offerings.length - 1 && (
                <Stack
                  bgcolor={({ palette }) => alpha(palette.neutrals.white, 0.7)}
                  position="absolute"
                  borderRadius={2}
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  display={{ xs: 'none', lg: 'flex' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {browseAllButton}
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Box
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          '& .next-mui-link': {
            width: '100%',
          },
        }}
      >
        {browseAllButton}
      </Box>
    </Stack>
  );
};
