import React, { useEffect, useMemo, useState } from 'react';

import Image from 'next/image';

import AliceCarousel from 'react-alice-carousel';
import { FormattedMessage } from 'react-intl';

import 'react-alice-carousel/lib/alice-carousel.css';

import { Box, Button, List, ListItem, ListItemText, Stack } from '@mui/material';

import { Constants, ROUTES } from '@arrived/common';

import { NextMuiLink } from '../Link';
import { InformationSection } from './InformationSection';

const howArrivedWorksCardsConfig = [
  {
    title: <FormattedMessage id="homepage-v2.how-arrived-works.card-1-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.how-arrived-works.card-1-description" />,
    imageUrl: 'how-arrived-works-step-1.jpg',
  },
  {
    title: <FormattedMessage id="homepage-v2.how-arrived-works.card-2-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.how-arrived-works.card-2-description" />,
    imageUrl: 'how-arrived-works-step-2.jpg',
  },
  {
    title: <FormattedMessage id="homepage-v2.how-arrived-works.card-3-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.how-arrived-works.card-3-description" />,
    imageUrl: 'how-arrived-works-step-3.jpg',
  },
  {
    title: <FormattedMessage id="homepage-v2.how-arrived-works.card-4-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.how-arrived-works.card-4-description" />,
    imageUrl: 'how-arrived-works-step-4.jpg',
  },
];

export const HowArrivedWorks = () => {
  const [selectedCardState, setSelectedCardState] = useState(0);

  const images = useMemo(
    () =>
      howArrivedWorksCardsConfig.map(({ imageUrl }, index) => (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            minHeight: {
              xs: '226px',
              md: '468px',
            },
          }}
          key={index}
        >
          <Image
            alt=""
            src={`${Constants.awsCdnLandingUrl}/${imageUrl}`}
            fill
            style={{
              objectFit: 'cover',
            }}
          />
        </Box>
      )),
    [],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedCardState((prev) => {
        if (prev === howArrivedWorksCardsConfig.length - 1) {
          return 0;
        }

        return prev + 1;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <InformationSection
      tagline={<FormattedMessage id="homepage-v2.how-arrived-works.tagline" />}
      title={<FormattedMessage id="homepage-v2.how-arrived-works.title" />}
      cta={
        <Button
          component={NextMuiLink}
          href={ROUTES.static.howItWorks}
          variant="contained"
          color="primary"
          sx={{ width: '100%', maxWidth: 'none' }}
        >
          <FormattedMessage id="homepage-v2.how-arrived-works.cta" />
        </Button>
      }
      my={0}
      mx={{ xs: 1.25, md: 6.25 }}
      px={{ xs: 2.5, md: 13.75 }}
      py={{ xs: 5.25, md: 15 }}
      boxShadow={
        '0px 62px 80px rgba(0, 0, 0, 0.02), 0px 40.1852px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 23.8815px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 12.4px 13px rgba(0, 0, 0, 0.01), 0px 5.05185px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.14815px 3.14815px rgba(0, 0, 0, 0.00481481)'
      }
      borderRadius="10px"
      border={({ palette }) => `1px solid ${palette.neutrals.platinum}`}
      dataE2e="homepage-v2.how-arrived-works"
    >
      <Stack direction={{ xs: 'column', lg: 'row' }} gap={{ lg: 12.5 }}>
        <Box
          minWidth="320px"
          minHeight={{ xs: '300px', md: '500px', lg: 0 }}
          flex={{ lg: '1 1 500px' }}
          position="relative"
        >
          <Stack position="absolute" top={0} left={0} bottom={0} right={0} alignItems="center">
            <AliceCarousel
              activeIndex={selectedCardState}
              animationDuration={750}
              autoPlay
              autoPlayInterval={4000}
              disableButtonsControls
              disableDotsControls
              items={images}
            />
          </Stack>
        </Box>
        <List sx={{ flex: '0 1 500px', minWidth: '340px' }}>
          {howArrivedWorksCardsConfig.map(({ title, description }, index) => (
            <ListItem
              key={index}
              // @ts-expect-error unhappy with the typing of SX's '&::before' pseudoselector
              sx={{
                position: 'relative',
                pl: 9.5,
                pr: 0,
                pb: { md: 4 },
                overflow: 'visible',
                minHeight: '124px',
                '&::before': {
                  content: `"${index + 1}"`,
                  position: 'absolute',
                  left: 2,
                  height: '43px',
                  width: '43px',
                  border: ({ palette }) => `2px solid ${palette.darkened.midnight}`,
                  borderRadius: '100%',
                  textAlign: 'center',
                  lineHeight: '36px',
                  backgroundColor: ({ palette }) => selectedCardState >= index && palette.darkened.midnight,
                  color: ({ palette }) => selectedCardState >= index && palette.neutrals.white,
                  transition: 'color 500ms, background-color 500ms',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: 23,
                  top: {
                    xs: 82,
                    md: 71,
                  },
                  border: ({ palette }) =>
                    `${selectedCardState > index ? '1px' : 0} solid ${palette.darkened.midnight}`,
                  width: '2px',
                  height: selectedCardState > index ? '100%' : '0px',
                  backgroundColor: ({ palette }) => selectedCardState > index && palette.darkened.midnight,
                  transition: 'height 0.75s linear',
                },
              }}
            >
              <ListItemText
                primary={title}
                primaryTypographyProps={{ variant: 'h6.medium', component: 'h4' }}
                secondary={description}
                secondaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </InformationSection>
  );
};
