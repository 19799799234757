import { useCallback } from 'react';

import path from 'path';

import { useRouter } from 'next/router';

import { Stack } from '@mui/material';

import { EVENTS, useIdentify, useTrack } from '@arrived/analytics';
import { getCompanyKpis, getCustomerCount, getOfferingsSearch } from '@arrived/api_v2';
import { HTMLHeadConstants, ROUTES, SessionStorageKeys } from '@arrived/common';
import { trackMarketingConversionEvent } from '@arrived/marketing';
import { MARKETING_CONVERSION_EVENTS } from '@arrived/models';
import { SEO } from '@arrived/router';

import {
  DiligenceProcess,
  Faqs,
  Hero,
  HowArrivedWorks,
  InThePress,
  PropertyCards,
  TopBenefits,
  ValueProps,
} from '../components/homepageV2';
import { EmailStart } from '../components/homepageV2/EmailStart';

path.resolve('./next.config.js');

export const getStaticProps = async () => {
  const [kpis, offerings, customerCount] = await Promise.all([
    getCompanyKpis(),
    getOfferingsSearch({ size: 4, sort: 'propertyRank:desc,id:desc' }).then((response) => response.data),
    getCustomerCount(),
  ]);

  return {
    props: {
      kpis,
      offerings,
      customerCount,
    },
    revalidate: 600,
  };
};

export const HomePage = ({ customerCount, offerings, kpis }: Awaited<ReturnType<typeof getStaticProps>>['props']) => {
  const router = useRouter();
  const track = useTrack();
  const identify = useIdentify();

  const handleEmailOnSubmit = useCallback(
    (position: string) => (email: string) => {
      if (email) {
        identify({
          email,
          user_setup_stage: 'lead',
          lead_created_at: new Date().toISOString(),
        });
        track(EVENTS.EMAIL_SUBSCRIPTION, { position });
        track(EVENTS.LEAD_FORM_COMPLETED, {
          email,
          lead_form_type: 'App - Home Page',
          referral_source: document.referrer,
          url: window.location.href,
        });

        trackMarketingConversionEvent({ email, type: MARKETING_CONVERSION_EVENTS.EMAIL_LEAD });

        // Set item in session storage to be used in signup page
        sessionStorage.setItem(SessionStorageKeys.LANDING_PAGE_EMAIL, email);
      }

      router.push(ROUTES.public.register);
    },
    [identify, track, router],
  );

  return (
    <Stack alignItems={{ md: 'center' }}>
      <SEO>
        <SEO.Title content={HTMLHeadConstants.title} excludeTrailingTitle />
        <SEO.Description content={HTMLHeadConstants.description} />
        <SEO.Keywords content={HTMLHeadConstants.keywords} />
        <SEO.Image content={HTMLHeadConstants.imageUrl} />
      </SEO>

      <Stack maxWidth={{ md: '1800px' }} alignItems={{ md: 'center' }} role="main" width="100%">
        <Hero handleEmailOnSubmit={handleEmailOnSubmit} />
        <InThePress />
        {/*<ReturnCards />*/}
        <ValueProps customerCount={customerCount} kpis={kpis} />
        <PropertyCards offerings={offerings} />
        {/* <BricksStack
          br="$2"
          borderWidth={1}
          borderColor="$onSurface.neutral.outlineAlt"
          elevation="$6"
          m="$2"
          mt="$10"
          py="$6"
          gap="$8"
          alignItems="center"
          $gtXxs={{
            px: '$4',
            m: '$8',
          }}
          $gtMd={{
            // @ts-expect-error -- This is fine for now, until we move everything to bricks.
            px: `${18 * 4}px`,
          }}
        >
          <TitleText token="title.heading.large" aria-level="2">
            <FormattedMessage id="comparison-chart.title" />
          </TitleText>
          <BricksStack p="$4">
            <ComparisonChart />
          </BricksStack>
        </BricksStack> */}
        <DiligenceProcess />
        <HowArrivedWorks />
        <TopBenefits />
        <Faqs />
        <EmailStart />
      </Stack>
    </Stack>
  );
};

HomePage.hasFooter = true;
