import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, Stack, Typography } from '@mui/material';

import { ROUTES } from '@arrived/common';

import { NextMuiLink } from '../Link';
import { InformationSection } from './InformationSection';
import { TopBenefitsImage } from './TopBenefitsImage';

const topBenefitsCardsConfig = [
  {
    title: <FormattedMessage id="homepage-v2.top-benefits.card-1-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.top-benefits.card-1-description" />,
    imageHeight: 151,
    imageUrl: 'why-arrived-01.png',
    imageWidth: 84,
  },
  {
    title: <FormattedMessage id="homepage-v2.top-benefits.card-2-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.top-benefits.card-2-description" />,
    imageHeight: 135,
    imageUrl: 'why-arrived-02.png',
    imageWidth: 131,
  },
  {
    title: <FormattedMessage id="homepage-v2.top-benefits.card-3-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.top-benefits.card-3-description" />,
    imageHeight: 133,
    imageUrl: 'why-arrived-03.png',
    imageWidth: 135,
  },
  {
    title: <FormattedMessage id="homepage-v2.top-benefits.card-4-title" values={{ br: <br /> }} />,
    description: <FormattedMessage id="homepage-v2.top-benefits.card-4-description" />,
    imageHeight: 127,
    imageUrl: 'why-arrived-04.png',
    imageWidth: 123,
  },
];

export const TopBenefits = () => {
  return (
    <InformationSection
      tagline={<FormattedMessage id="homepage-v2.top-benefits.tagline" />}
      title={<FormattedMessage id="homepage-v2.top-benefits.title" />}
      cta={
        <Button
          component={NextMuiLink}
          href={ROUTES.static.whyInvest}
          variant="contained"
          color="primary"
          sx={{ width: '100%', maxWidth: 'none' }}
        >
          <FormattedMessage id="homepage-v2.top-benefits.cta" />
        </Button>
      }
      dataE2e="homepage-v2.top-benefits"
    >
      <Stack
        sx={{
          flexDirection: 'row',
          flexWrap: { xs: 'no-wrap', md: 'wrap' },
          gap: { xs: 3, md: 5 },
          pb: { xs: 2, md: 0 },
          overflow: { xs: 'auto', md: 'hidden' },
        }}
        component="div"
        tabIndex={0}
      >
        {topBenefitsCardsConfig.map(({ title, description, imageHeight, imageUrl, imageWidth }, index) => (
          <Stack
            sx={{
              flex: { xs: '0 1 auto', md: '0 1 calc(50% - 24px)' },
              flexDirection: { xs: 'column', md: 'row' },
              backgroundColor: ({ palette }) => palette.neutrals.white,
              border: ({ palette }) => `1px solid ${palette.neutrals.platinum}`,
              borderRadius: '10px',
              p: 1.5,
              gap: 2.5,
            }}
            key={index}
          >
            <TopBenefitsImage imageHeight={imageHeight} imageUrl={imageUrl} imageWidth={imageWidth} />
            <Stack sx={{ justifyContent: 'center', gap: 2 }}>
              <Typography
                variant="h5.medium"
                component="h4"
                sx={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  hyphens: 'auto',
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  hyphens: 'auto',
                }}
              >
                {description}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </InformationSection>
  );
};
